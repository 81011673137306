html {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

@keyframes rotatingBox {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.left-white-box {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 64px;
  height: 64px;
  background-color: white;
}

.left-white-box:hover {
  animation: rotatingBox .1s linear infinite;
}

.right-white-box {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 200px;
  height: 200px;
  font-size: 136px;
  color: #7BD0FF;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.bottom-left-text {
  position: absolute;
  bottom: 8px;
  left: 28px;
  font-size: 136px;
  color: white;
}

.privacy-policy {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}